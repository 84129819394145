export const GlobalSearchList = [
    { title: 'Home', url: '/', externalUrl: '', shortNote: 'ExploreHomePage' },
    { title: 'Blogs', url: '/blogs', externalUrl: '', shortNote: 'DiscoverBlogs' },
    { title: 'Store', url: '/store', externalUrl: '', shortNote: 'BrowseStore' },
    { title: 'Apps', url: '/store', externalUrl: '', shortNote: 'ExploreApps' },
    { title: 'Learning ', url: '/learning', externalUrl: '', shortNote: 'AdvanceWithLearning' },
    { title: 'Escape Run', url: '', externalUrl: 'https://play.google.com/store/apps/details?id=com.dhimanp.EscapeRun', shortNote: 'PlayEscapeRun' },
    { title: 'PinBall Planet', url: '', externalUrl: 'https://play.google.com/store/apps/details?id=com.dhimanp.PinBallPlanet', shortNote: 'PlayPinBallPlanet' },
    { title: 'Count My Cash', url: '', externalUrl: 'https://play.google.com/store/apps/details?id=com.dhimanp.CountMyCash', shortNote: 'TrackFinances' },
    { title: 'Guessing The Number', url: '', externalUrl: 'https://play.google.com/store/apps/details?id=com.dhimanp.GuessingTheNumber', shortNote: 'PlayGuessingTheNumber' },
    { title: 'Javascript Runner', url: '/apps/web-apps/javascript-runner', externalUrl: '', shortNote: 'RunJavaScriptCode' },
    { title: 'HTML Viewer', url: '/apps/web-apps/html-viewer', externalUrl: '', shortNote: 'ViewHTML' },
    { title: 'Guid Generator', url: '/apps/web-apps/guid-generator', externalUrl: '', shortNote: 'GenerateGUID' },
    { title: 'InternetSpeedTest', url: '/apps/web-apps/internet-speed-test', externalUrl: '', shortNote: 'TestInternetSpeed' },
    { title: 'PasswordGenerator', url: '/apps/web-apps/password-generator', externalUrl: '', shortNote: 'GenerateSecurePasswords' },
    { title: 'MotivationalQuotes', url: '/apps/web-apps/motivational-quotes', externalUrl: '', shortNote: 'GetMotivationalQuotes' },
    { title: 'BirthdayWishes', url: '/apps/web-apps/birthday-wishes', externalUrl: '', shortNote: 'CreateBirthdayWishes' },
    { title: 'Privacy Policy', url: 'policies/privacy-policy', externalUrl: '', shortNote: 'ViewPrivacyPolicy' },
    { title: 'FAQ', url: '/policies/faq', externalUrl: '', shortNote: 'FindFAQ' },
    { title: 'ContactUs', url: '/contacts', externalUrl: '', shortNote: 'ContactUs' },
    { title: 'AboutUs', url: '/about-us', externalUrl: '', shortNote: 'LearnAboutUs' },
  ];
  