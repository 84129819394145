import { Component } from '@angular/core';
import { GlobalSearchService } from 'src/app/services/global-search.service';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent {

  items = [{name:''}];
  filteredItems:any[] = [];

  constructor(private searchService: GlobalSearchService) {}

  ngOnInit() {
    this.searchService.searchQuery$.subscribe(query => {
      this.filteredItems = this.items.filter(item =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
    });
  }
}
