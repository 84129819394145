import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../generic/api.service';
import { Feedback } from 'src/app/Models/Contacts/Feedback';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private apiService: ApiService) { }
  submitFeedback(feedback: Feedback): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('name', feedback.name!);
    formData.append('email', feedback.email!);
    formData.append('message', feedback.message);
    if (feedback.files?.length! > 0 && feedback.files) {
      formData.append('image', feedback.files[0], feedback.files[0].name);
    }
    return this.apiService.post("feedback", formData)
  }
}