import { Injectable } from '@angular/core'
import { Observable, of, throwError, timer } from 'rxjs'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ApiService {
  options = {};
  token: string = "";

  private readonly apiBaseUrl = environment.api.baseUrl;
  constructor(private httpClient: HttpClient,
    private router: Router
  ) {
    this.options = {
      responseType: 'json',
    }
  }

  get<T>(url: string): Observable<T> {
    return this.httpClient
      .get<T>(`${this.apiBaseUrl}/${url}`, this.options)
      .pipe(
        retry({
          count: 3,
          delay: (error: any) => {
            console.log(error);
            if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
              throw error;
            }
            // Retry for other errors
            return timer(1000);
          },
        }),
        catchError(this.handleError)
      );
  }


  delete<T>(url: string): Observable<T> {
    return this.httpClient
      .delete<T>(`${this.apiBaseUrl}/${url}`, this.options)
      .pipe(catchError(this.handleError));
  }

  post<T>(url: string, data: any): Observable<T> {
    return this.httpClient
      .post<T>(`${this.apiBaseUrl}/${url}`, data, this.options)
      .pipe(catchError(this.handleError));
  }

  put<T>(url: string, data: any): Observable<T> {
    return this.httpClient
      .put<T>(`${this.apiBaseUrl}/${url}`, data, this.options)
      .pipe(catchError(this.handleError));
  }

  patch<T>(url: string, data: any): Observable<T> {
    return this.httpClient
      .patch<T>(`${this.apiBaseUrl}/${url}`, data, this.options)
      .pipe(catchError(this.handleError));
  }

  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 0) {
      console.error('A client-side or network error occurred:', error.error);
    } else if (error.status === 403 || error.status === 401) {
      this.router.navigate(['/access-denied']);
      return throwError(() => new Error(error.status.toString()));
    } else if (error.status === 404) {
      return throwError(() => new Error('Not found'));
    } else if (error.status === 400) {
      return throwError(() => new Error(this.formatApiError(error.error as any)));
    } else {
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  };

  private formatApiError(apiError: any): string {
    let apiErrorMessage = '';
    if (apiError.errors) {
      apiError.errors.forEach((err: any) => {
        apiErrorMessage += err.errorCode ? `${err.errorCode}^` : `${err.message}^`;
      });
    } else {
      Object.values(apiError.errors).forEach(errArray => {
        (errArray as string[]).forEach(err => {
          apiErrorMessage += `${err}^`;
        });
      });
    }
    return apiErrorMessage;
  }
}
