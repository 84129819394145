export const environment = {
  production: false,
  app: {
    baseUrl: window.location.href
  },
  api: {
    baseUrl: "https://dhimanp-node-apis.onrender.com/api"
  }, jsonData:
  {
    baseUrl: "https://raw.githubusercontent.com/Dhiman-Paras/dhimanp-assets/main/dev/database"
  },
  googleAnalytics: ''

};
