import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WepAppInfo } from 'src/app/Models/WebApp/WebAppInfo';
import { Language, UserProfile } from 'src/app/Models/Users/User';
import { UserProfileService } from 'src/app/services/accounts/user-profile.service';
import { Localizations } from 'src/app/shared/utility/language';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [MessageService]

})
export class UserProfileComponent {
  form: any;
  sidebarVisible: boolean = false;
  name: string = "User";
  submitted: boolean = false;

  appInfo: WepAppInfo = {
    name: 'dhimanp',
    version: '1.0.0',
    url: "https://www.dhimanp.com",
    description: 'A product-based team that offers innovative software solutions and insightful blogs to empower developers and tech enthusiasts.'
  }

  userInfo: UserProfile | undefined;
  selectedLanguage: Language | Language.English = 0;
  languages: any[] = [
    { label: "English", value: 0 },
    { label: "Hindi", value: 1 },
  ];
  constructor(
    private translate: TranslateService,
    private userProfileService: UserProfileService,
    private messageService: MessageService,
  ) {
    // UserConfig = {
    //   name: this.name,
    //   profileUrl: `dhimanp.com/user-profile/${this.name}/extra-info`,
    //   language: Language.English,
    //   stream: Stream.General,
    //   theme: Theme.Theme1,
    //   periodDays: 7
    // }

    userProfileService.get().subscribe(x => {
      this.userInfo = x!;
      this.selectedLanguage = x?.userConfig.language!;
      this.name = x?.userConfig.name!;
    });
  }

  openDialog() {
    this.sidebarVisible = true;
  }
  onSubmit(name: any) {
    this.submitted = true;
    if (name.valid) {
      this.submitted = true;
    }

    if (this.userInfo && this.userInfo.userConfig) {
      this.userInfo.userConfig.name = name.trim();
    }
    this.userProfileService.addOrUpdate(this.userInfo!);
    this.messageService.add({
      severity: 'success', summary: this.translate.instant('Updated'),
      detail: this.translate.instant('NameUpdated')
    });
  }

  onLanguageChange(event: any) {
    if (this.userInfo && this.userInfo.userConfig) {
      this.userInfo.userConfig.language = parseInt(event.target.value);
      this.translate.use(Localizations[this.userInfo.userConfig.language]);
      this.messageService.add({
        severity: 'success', summary: this.translate.instant('Updated'),
        detail: this.translate.instant('LanguageUpdated')
      });
    }
    this.userProfileService.addOrUpdate(this.userInfo!);

  }
}
