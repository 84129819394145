import { Language, Stream, Theme, UserProfile } from "src/app/Models/Users/User";
import { environment } from "src/environments/environment";

export const UserProfileDefault: UserProfile = {
    userConfig: {
        name: "User",
        language: Language.English,
        stream: Stream.General,
        theme: Theme.Theme1,
        profileUrl: environment.app.baseUrl

    },
    userApps: []
}