import { Component } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  providers: [DialogService],
})
export class ConfirmDialogComponent {
  displayData = { message: "", details: "" }
  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef,
  ) { 
  }

  ngOnInit(): void {
    this.displayData = this.config.data;
  }

  closeModal(decision: boolean = false) {
    this.ref.close(decision);
  }
}
