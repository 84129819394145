import { Component, ViewChild } from '@angular/core';
import { Sidebar } from 'primeng/sidebar';

@Component({
  selector: 'app-topbar',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @ViewChild('sidebarRef') sidebarRef!: Sidebar;
  appNotificationVisibility: boolean = false;

  closeCallback(e: any): void {
    this.sidebarRef.close(e);
  }

  sidebarVisible: boolean = false;

  elem = document.documentElement;

  isFullScreenBtn: boolean = true;
  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.isFullScreenBtn = false;
      this.elem.requestFullscreen();
    } else if ((this.elem as any).webkitRequestFullscreen) { // Safari/iOS support
      this.isFullScreenBtn = false;
      (this.elem as any).webkitRequestFullscreen();
    }
  }
  
  closeFullscreen() {
    if (document.exitFullscreen) {
      this.isFullScreenBtn = true;
      document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) { // Safari/iOS support
      this.isFullScreenBtn = true;
      (document as any).webkitExitFullscreen();
    }
  }
  
}
