import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  constructor(private router: Router,
    private titleService: Title
  ) {

    this.titleService.setTitle("dhimanp - Page Not Found");
  }

  openAppLinks(url: string) {
    this.router.navigateByUrl(url);
  }
}
