<div id="splash-screen" *ngIf="showSplash">
    <div class="text-7xl">
        <span class="bg-white p-3 mx-3" style="border-radius: 35px 36px 37px 0px"><span class="text-danger logo">
                dhiman</span><span class="text-warning logo">p </span></span>
    </div>
</div>
<div id="main-content" [class.hidden]="showSplash">
    <app-topbar></app-topbar>
    <div class="m-0 " style="height: 100hv;">
        <button pButton pRipple class="p-button-primary right-align feedback-btn text-2xl" icon="pi pi-comment"
            (click)="showFeedbackDialog()"></button>
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
</div>