<span class="ml-3 cursor-pointer text-white ">
    <!-- tooltipPosition="bottom" [pTooltip]="'Notifications'| translate" -->

    <i *ngIf="newNotificationsCount>0" class="pi pi-bell  " pBadge [value]="newNotificationsCount.toString()"
        severity="warning" (click)="openDialog()"></i>
    <i *ngIf="newNotificationsCount<=0" class="  pi pi-bell " (click)="openDialog()" severity="warning"></i>
</span>

<p-sidebar [(visible)]="sidebarVisible" position="right" [blockScroll]="true" styleClass=" sm:w-8 md:w-3 mx-0">
    <ng-template pTemplate="header">
        <span class="font-semibold text-xl">{{'Notifications'| translate}}</span>
    </ng-template>

    <div *ngIf="notifications.length<=0">
        <p class="mt-6 text-center">{{'NoNotification'| translate}}</p>
        <div class="centered-content ">
            <i class="fa fa-bell text-center text-8xl text-warning px-6 text-200 mx-5" aria-hidden="true"></i>

        </div>
    </div>
    <div class="mb-2 surface-200 p-2 border-round " *ngFor="let notification of notifications">
        <div class="grid w-full m-0">
            <div class="col-11">
                <i [class]="'pi font-bold  mr-2' + getAndSetSeverityIcon(notification.severity)"></i>
                <span>
                    <span [class]="getAndSetSeverityClass(notification.severity)+' font-bold'">
                        {{getAndSetSeverityText(notification.severity)}}
                    </span>
                    | <span class="text-sm">
                        {{notification.time| date:'short'}}
                    </span>
                </span>
            </div>
            <div class="col-1 ">
                <i class="pi pi-times-circle text-red-700 cursor-pointer " style="float: right;"
                    (click)="deleteNotification(notification)"></i>
            </div>
        </div>
        <div class="grid m-0">
            <div class="col-2 ">
                <p-avatar image="../dhimanp.favicon.png" shape="circle" />
                <!-- <p-avatar *ngIf="notification.senderIcon || notification.senderIcon!==''"
                    [image]="notification.senderIcon!" icon="pi pi-user" shape="circle" /> -->
            </div>
            <div class="col-10">
                <h6><span> {{notification.subject}} </span>
                    <span *ngIf="isNewNotification(notification.time)" style="float: right;">
                        <p-tag [value]="'New'|translate" [rounded]="true" />
                    </span>
                </h6>
            </div>
            <p class="p-2" style="text-align: justify;">
                <span [innerHTML]="notification.content"></span>
            </p>
            <span *ngIf="notification.url || notification.modal" (click)="openUrlOrDialog(notification)"
                class="cursor-pointer  font-bold text-right text-primary">
                # {{'TryIt'| translate}}!</span>
        </div>
    </div>
    <!--end of notification template-->

    <ng-template pTemplate="footer">
        <h6 class="">{{newNotificationsCount}} {{'NewNotifications'| translate}} | {{'Total'|translate}}:
            {{notifications.length}}
        </h6>
        <hr />
        <div class="grid m-0 p-0">
            <div class="col-8">
                <h6>{{'ShareWebsite'| translate}} </h6>
                <a href="https://dhimanp.com/" target="_blank">
                    <span> {{appInfo.name}}.com</span>
                </a>
                <span class="ml-3"> <i class="fa fa-share-square cursor-pointer"></i>
                </span>
                <p>{{'Version'| translate}} {{appInfo.version}}</p>
                <a routerLink="/policies/privacy-policy" class="no-underline "
                    (click)="sidebarVisible=false">{{'PrivacyPolicy' |
                    translate}}</a>
                <!-- <p-divider layout="vertical" /> -->
            </div>
            <div class="col-4">
                <qrcode [qrdata]="appInfo.url" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </div>
    </ng-template>
</p-sidebar>
<!-- <p-button (onClick)="sidebarVisible = true" icon="pi pi-arrow-right" /> -->