<div class=" p-8 md:px-6 lg:px-8 text-center ">
    <div class="mb-3 ">
        <span class="my-3   text-8xl">☹️</span>
        <h1 class=" ">404 {{'PageNotFound'| translate}} </h1>
        <p class="p-mb-3">
            {{'WeCouldntFindThePage'| translate}}
        </p>
        <button pButton pRipple [label]="'GoHome' | translate" type="button" class="p-button-raised mb-2 mx-3"
            (click)="openAppLinks('/')"></button>
        <button pButton pRipple [label]="'ExploredhimanpStore' | translate" type="button" class="p-button-raised mb-2"
            (click)="openAppLinks('/store')"></button>
        <button pButton pRipple [label]="'ExploreOurBlogs' | translate" type="button" (click)="openAppLinks('blogs')"
            class="p-button-outlined mx-3 mb-2"></button>
        <button pButton pRipple [label]="'ExploreOurCourses' | translate" type="button"
            (click)="openAppLinks('/learning')" class="p-button-outlined mb-2"></button>
        <button pButton pRipple [label]="'Contacts' | translate" type="button" (click)="openAppLinks('/contacts')"
            class="p-button-outlined mb-2 mx-3"></button>
    </div>
</div>