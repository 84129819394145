import { Notification, Serverity } from "src/app/Models/Notifications/Notification";
export const Notifications: Notification[] = [];

Notifications.push(
    {
        id: "1",
        idN: 1,
        subject: "Bookmark dhimanp.com",
        content: "⭐ Don't miss out—bookmark dhimanp.com today for all the latest updates and exclusive content! 📌✨",
        time: new Date('11-23-2024'),
        severity: Serverity.Information,
    },
    {
        id: "2",
        idN: 2,
        subject: "New Arrivals at dhimanp Store!",
        content: "Hi there! 👋, Check out the latest updates at dhimanp Store! Discover new apps, tools, and exclusive offers.",
        time: new Date('11-23-2024'),
        severity: Serverity.Information,
        url: '/store'
    },
    {
        id: "3",
        idN: 3,
        subject: "Upcoming Blog Removal Notification",
        content: `<p>We are informing you that the following blogs will be removed from our platform. This change will take effect on <strong>December 22, 2024</strong>:</p>
<ol>
    <li><strong>Best Chrome Extensions for Web Developers</strong></li>
    <li><strong>Proof of Concept (POC) in Software Development</strong></li>
    <li><strong>What is Low-Level System Design?</strong></li>
    <li><strong>Top 11 Tips to Improve Your Logic Building in Programming</strong></li>
</ol>
<p>Thank you for your understanding.</p>
`,
        time: new Date('12-06-2024'),
        severity: Serverity.Information,
    },

    {
        id: "4",
        idN: 4,
        subject: "Upcoming Apps Removal Notification",
        content: `
        <p>We would like to inform you that the following apps will be removed from our platform, effective <strong>December 22, 2024</strong>:</p>
<ol>
    <li><strong>Guid-Generator</strong></li>
    <li><strong>HTML Viewer</strong></li>
    <li><strong>Javascript Runner</strong></li>
</ol>
<p>Thank you for your understanding.</p>
`,
        time: new Date('12-06-2024'),
        severity: Serverity.Information,
    },
);