<div class="main-bg m-0 pt-1 pb-2">
  <div class="pt-1 ">
    <!-- <button class="btn btn-light my-1 ml-3 mr-1"> -->
    <i class="pi pi-align-justify mx-3 text-white cursor-pointer" (click)="sidebarVisible = true"></i>
    <!-- </button> -->

    <a routerLink="/" class="no-underline mr-2">
      <span class="bg-white p-2" style="border-radius: 35px 36px 37px 0px"><span
          class="text-danger logo">dhiman</span><span class="text-warning logo">p</span></span>
    </a>
    <a routerLink="/store" class=" text-white p-1 ">
      <i class="pi pi-th-large      text-2xl "></i>
    </a>
    <span class="right-align pr-4">

      <!-- <button class="btn text-white" (click)="closeFullscreen()">
        <i class="pi pi-search"></i>
      </button> -->

      <!-- <app-notification></app-notification>

      <app-user-profile></app-user-profile> -->

      <button *ngIf="isFullScreenBtn" class="btn text-white" (click)="openFullscreen()">
        <i class="fa fa-arrows-alt" aria-hidden="true"></i>

      </button>
      <button *ngIf="!isFullScreenBtn" class="btn text-white" (click)="closeFullscreen()">
        <i class="fa fa-compress" aria-hidden="true"></i>

      </button>

    </span>
  </div>
</div>

<div class=" m-0 flex justify-content-center ">
  <p-sidebar #sidebarRef [(visible)]="sidebarVisible" [blockScroll]="true">
    <ng-template pTemplate="header">
      <span class="font-semibold text-xl">
        <!-- <a routerLink="/" (click)="sidebarVisible = false"> -->
        <span class="font-semibold text-2xl text-primary">
          <span class="main-bg p-2" style="border-radius: 35px 36px 37px 0px"><span
              class="text-white">dhiman</span><span class="text-white">p</span></span>
        </span>

        <!-- </a> -->
      </span>
    </ng-template>
    <ng-template pTemplate="headless">
      <div class="flex flex-column h-full">
        <!-- <div
          class="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0"
        >
          <span class="inline-flex align-items-center gap-2">
            
          </span> 
        </div> -->
        <div class="overflow-y-auto">
          <ul class="list-none px-3 m-0 ">
            <li>
              <a pRipple
                class="flex align-items-center no-underline 
                 cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                routerLink="/" (click)="sidebarVisible = false">
                <i class="pi pi-home mr-2" aria-hidden="true"></i>

                <span class="font-medium">Home</span>
              </a>
            </li>
            <li>
              <a pRipple
                class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                routerLink="/blogs" (click)="sidebarVisible = false">
                <i class="fa fa-book mr-2" aria-hidden="true"></i>

                <span class="font-medium">Blogs</span>
              </a>
            </li>
            <li>
              <a pRipple
                class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                routerLink="/learning" (click)="sidebarVisible = false">
                <i class="fa fa-book mr-2" aria-hidden="true"></i>

                <span class="font-medium">Learning</span>
              </a>
            </li>
          </ul>
          <ul class="list-none px-3 m-0">
            <!-- <li>
              <div pRipple pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                leaveActiveClass="slideup"
                class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple">
                <span class="font-medium">
                  <i class="fa fa-desktop mr-2"></i>

                  Apps
                </span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden">
                <li>
                  <a pRipple pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                    leaveActiveClass="slideup"
                    class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
                    <i class="pi pi-globe mr-2" aria-hidden="true"></i>
                    <span class="font-medium">Web Apps</span>
                    <i class="pi pi-chevron-down ml-auto"></i>
                  </a>
                  <ul
                    class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    <li>
                      <a pRipple
                        class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                        routerLink="/apps/web-apps/guid-generator" (click)="sidebarVisible = false">
                        <i class="fa fa-id-badge mr-2" aria-hidden="true"></i>
                        <span class="font-medium">Guid Generator</span>
                      </a>
                    </li>
                    <li>
                      <a pRipple
                        class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                        routerLink="/apps/web-apps/password-generator" (click)="sidebarVisible = false">
                        <i class="fa fa-key mr-2" aria-hidden="true"></i>
                        <span class="font-medium">Password Generator</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> -->
            <li>
              <a pRipple
                class="flex align-items-center no-underline cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                routerLink="store" (click)="sidebarVisible = false">
                <i class="pi pi-th-large                mr-2" aria-hidden="true"></i>

                <span class="font-medium">Store</span>
              </a>
            </li>
          </ul>
          <!-- <ul class="list-none px-3 m-0">
            <li>
              <div
                pRipple
                pStyleClass="@next"
                enterClass="hidden"
                enterActiveClass="slidedown"
                leaveToClass="hidden"
                leaveActiveClass="slideup"
                class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple"
              >
                <span class="font-medium">Learning</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden">
                <li>
                  <a
                    pRipple
                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                    routerLink="/blogs"
                    (click)="sidebarVisible = false"
                  >
                    <i class="fa fa-book mr-2" aria-hidden="true"></i>

                    <span class="font-medium">Blogs</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul> -->

          <ul class="list-none px-3 m-0">
            <li>
              <div pRipple pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                leaveActiveClass="slideup"
                class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple">
                <span class="font-medium">Contacts</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden">
                <li>
                  <a pRipple
                    class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                    routerLink="/about-us" (click)="sidebarVisible = false">
                    <i class="fa fa-info-circle mr-2" aria-hidden="true"></i>

                    <span class="font-medium">About</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                    class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                    routerLink="/contacts" (click)="sidebarVisible = false">
                    <i class="fa fa-user-circle mr-2" aria-hidden="true"></i>

                    <span class="font-medium">Contact</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- <div class="mt-auto">
          <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
          <a
            pRipple
            class="m-3 flex align-items-center cursor-pointer p-3 gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
          >
            <p-avatar
              image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"
              shape="circle"
            />
            <span class="font-bold">Amy Elsner</span>
          </a>
        </div> -->
      </div>
    </ng-template>
  </p-sidebar>
</div>