import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GlobalSearchService } from './services/global-search.service';
import { FeedbackFormComponent } from './contacts/feedback-form/feedback-form.component';
import { UserProfileService } from './services/accounts/user-profile.service';
import { UserProfileDefault } from './Database/Defaults/Account/UserProfile';
import { NotificationService } from './services/notifications/notification.service';
import { Serverity } from './Models/Notifications/Notification';
import { GoogleAnalyticsService } from './services/google/google-analytics.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService]

})
export class AppComponent implements OnInit {

  title = 'dhimanp';
  constructor(
    private dialogService: DialogService,
    private globalSearchService: GlobalSearchService,
    private translate: TranslateService,
    private userProfileService: UserProfileService,
    private notificationService: NotificationService,
    private _$gaService: GoogleAnalyticsService
  ) {

    userProfileService.get().subscribe(x => {
      if (!x?.userConfig) {
        userProfileService.addOrUpdate(UserProfileDefault).subscribe(x => {
          notificationService.send(
            {
              id: "1",
              idN: 1,
              subject: "Profile Setup",
              content: "Your profile has been successfully set up with the default settings. You’re all set to start using the application!",
              time: new Date(),
              severity: Serverity.Information,
              url: '/store'
            }
          );
        });
      }
    });
    translate.use("en");


  }
  ngOnInit(): void {
    this._$gaService.init();

    // this.notificationService.deleteOldLocalNotifications(2).subscribe();
  }


  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.globalSearchService.setSearchQuery(input.value);
  }

  showFeedbackDialog() {
    this.dialogService.open(FeedbackFormComponent, {
      header: "Feedback Form",
      showHeader: true,
      // contentStyle: { overflow: 'auto', borderRadius: "5px" },
      // baseZIndex: 10000,
      modal: true,
      width: "70%"
    });
  }
}
