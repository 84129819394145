export interface UserProfile {
    userConfig: UserConfig,
    userApps: UserApp[]
}

export interface UserConfig {
    name: string;
    profileUrl: string;
    language: Language;
    stream: Stream;
    theme: Theme;
    periodDays: number;// for new tag
}

export interface UserApp {
    id: number;
    appIdp: number;
    isFaviourate?: boolean;
}

export enum Language {
    English,
    Hindi
}
export enum Stream {
    General,
    IT,
    Account
}
export enum Theme {
    Theme1
}