import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-page',

  providers: [DialogService],

  templateUrl: './error-page.component.html',

})
export class ErrorPageComponent implements OnInit {

  displayData = { message: "", details: "" }
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if (this.config.data.message != "") {
      this.displayData.message = this.config.data.message;
    }
    if (this.config.data.details.details != "") {
      this.displayData.details = this.config.data.details;
    }
  }

  closeModal() {
    this.ref.close(true);
  }
}
