import { Component, ViewChild } from '@angular/core';
import { Sidebar } from 'primeng/sidebar';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GlobalSearchComponent } from 'src/app/shared/global-search/global-search.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [MessageService]

})
export class HeaderMenuComponent {

  @ViewChild('sidebarRef') sidebarRef!: Sidebar;
  appNotificationVisibility: boolean = false;
  sidebarVisible: boolean = false;
  elem = document.documentElement;
  isFullScreenBtn: boolean = true;

  constructor(
    private translate: TranslateService,
    private messageService: MessageService,
    private dialogService: DialogService,

  ) { }

  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.isFullScreenBtn = false;
      this.elem.requestFullscreen();
    } else if ((this.elem as any).webkitRequestFullscreen) { // Safari/iOS support
      this.isFullScreenBtn = false;
      (this.elem as any).webkitRequestFullscreen();
    }
  }

  closeCallback(e: any): void {
    this.sidebarRef.close(e);
  }

  closeFullscreen() {
    if (document.exitFullscreen) {
      this.isFullScreenBtn = true;
      document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) { // Safari/iOS support
      this.isFullScreenBtn = true;
      (document as any).webkitExitFullscreen();
    }
  }
  errorRefDialog: DynamicDialogRef | undefined;

  openGlobalSearch() {
    this.OpenDynamincModal("Global Search", this.errorRefDialog!);
  }
  OpenDynamincModal = (header: string, dynamicMoal: DynamicDialogRef) => {
    dynamicMoal = this.dialogService.open(GlobalSearchComponent,
      {
        header: header,
        showHeader: false,
        contentStyle: { overflow: 'auto', borderRadius: "5px" },
        styleClass: "rounded ",
        baseZIndex: 10000,
        modal: true,
        data: {
          message: "",
          details: ""
        },
        width: "70%",
        closeOnEscape: true,
        closable: true
      })
    dynamicMoal.onClose.subscribe(result => {
    });
  }
}