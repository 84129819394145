import { Component } from '@angular/core';
import { Feedback } from 'src/app/Models/Contacts/Feedback';
import { FeedbackService } from 'src/app/services/messages/feedback.service';
import { OnInit, ViewChild } from '@angular/core';
import { Message, MessageService, PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError } from 'rxjs';
import { ErrorPageComponent } from 'src/app/shared/error-page/error-page.component';
import { FormBuilder, Validators } from '@angular/forms';
import { showError } from 'src/app/shared/utility/dialog';
import { UserProfileService } from 'src/app/services/accounts/user-profile.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
  providers: [MessageService, DialogService]

})
export class FeedbackFormComponent implements OnInit {
  savingBtnIcon: string = "";
  submitted: boolean = false;
  errorRefDialog: DynamicDialogRef | undefined;
  isErrorDialogDisplayed: boolean = false;

  constructor(private feedbackService: FeedbackService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private dialogService: DialogService,
    private userProfileService: UserProfileService
  ) { }

  descriptionValidationClass = " ";
  feedback: any = undefined;
  uploadedFiles: any[] = [];
  form: any;
  @ViewChild('fileUplaod', { static: false }) fileUpload: any;

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    const {
      name,
      message,
      files,
    } = this.feedback || {};
    this.form = this.fb.group({
      name: [name ?? null,],
      message: [message ?? null, [Validators.required]],
      files: [files ?? this.uploadedFiles, []],
    });
  }

  submitForm() {
    this.submitted = true;
    if (!this.form.value.message) {
      this.descriptionValidationClass = " border-red-500 border-1 border-round ";
    } else {
      this.descriptionValidationClass = " ";
      this.form.value.message = this.form.value.message.trim();
    }
    if (this.form.invalid) {
      return;
    }
    if (this.form.value.files.length > 1) {
      return;
    }
    if (this.form.value.name)
      this.form.value.name = this.form.value.name.trim();

    if (!this.feedback) {
      if (this.form.value.name === null || this.form.value.name === "") {
        this.userProfileService.get().subscribe(x => {
          this.form.value.name = x?.userConfig.name || "";
        })
      }
      this.sendFeedback(this.form.value);
    }
  }

  sendFeedback(feedback: Feedback) {
    this.savingBtnIcon = " pi pi-spin pi-spinner";
    const successMessage: string = this.translate.instant("Sending");
    const summary: string = this.translate.instant("Successfully");
    this.messageService.add({ severity: 'success', summary: summary, detail: successMessage, life: 2000 });

    this.savingBtnIcon = " pi ";
    this.feedbackService.submitFeedback(feedback).pipe(
      catchError(error => {
        this.savingBtnIcon = " pi ";
        console.log("Error occurred:", error);
        showError("", "", error.message, this.errorRefDialog!, this.dialogService, ErrorPageComponent)
        throw error;
      })
    ).subscribe({
      next: (response: any) => {
        console.log(this.form.value);
        this.resetFormValidations();
      }
    });
  }

  resetFormValidations() {
    this.form.reset();
    this.uploadedFiles = [];
    this.submitted = false;
    this.descriptionValidationClass = "";
    this.buildForm();
    this.fileUpload?.clear();
    this.fileUpload.msgs = [] as Message[];
  }

  onFileSelect(event: any) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  onDescriptionChange(event: any) {
    if (event.target.innerText === "" || event.target.innerText === "\n") {
      this.descriptionValidationClass = " border-red-500 border-1 border-round ";
    } else {
      this.descriptionValidationClass = "";
    }
  }
  onRemoveFile(file: any) {
    this.uploadedFiles = this.uploadedFiles.filter(x => x !== file);
    this.form.value.files = this.fileUpload.files = this.uploadedFiles;
    if (this.uploadedFiles.length <= 5) {
      this.fileUpload.msgs = [] as Message[];
    }
  }

  formatSize(bytes: number) {
    const k = 1024;
    const dm = 1;
    const sizes = [4000];
    if (bytes === 0) {
      return `0 ${sizes[0]}`;
    }
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    return `${formattedSize} ${sizes[i]}`;
  }
}