import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { GlobalSearchService } from './services/global-search.service';
import { FeedbackFormComponent } from './contacts/feedback-form/feedback-form.component';
import { UserProfileService } from './services/accounts/user-profile.service';
import { UserProfileDefault } from './Database/Defaults/Account/UserProfile';
import { NotificationService } from './services/notifications/notification.service';
import { GoogleAnalyticsService } from './services/google/google-analytics.service';
import { Localizations } from './shared/utility/language';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService]

})
export class AppComponent implements OnInit {

  title = 'dhimanp';
  showSplash = true; // Control visibility of the splash screen

  constructor(
    private dialogService: DialogService,
    private globalSearchService: GlobalSearchService,
    private translate: TranslateService,
    private userProfileService: UserProfileService,
    private notificationService: NotificationService,
    private _$gaService: GoogleAnalyticsService
  ) {
    if (localStorage) localStorage.removeItem("notifications");
    userProfileService.get().subscribe(x => {
      if (!x?.userConfig || !x.userConfig.periodDays) {
        userProfileService.addOrUpdate(UserProfileDefault).subscribe(x => {
          //   notificationService.send(
          //     {
          //       id: "1",
          //       idN: 1,
          //       subject: "Profile Setup",
          //       content: "Your profile has been successfully set up with the default settings. You’re all set to start using the application!",
          //       time: new Date(),
          //       severity: Serverity.Information,
          //       url: '/store'
          //     }
          //   );
        });
      }
      let laguageIndex = 0;
      try {
        laguageIndex = x?.userConfig.language!
      } catch (e) {
        laguageIndex = 0;
      }
      translate.use(Localizations[laguageIndex]);
    });
    //  userProfileService.addOrUpdate(UserProfileDefault).subscribe(x => {
    //     notificationService.send(
    //       {
    //         id: "2",
    //         idN: 2,
    //         subject: "New Arrivals at DhimanP Store!",
    //         content: "Hi there! 👋, Check out the latest updates at DhimanP Store! Discover new apps, tools, and exclusive offers.",
    //         time: new Date(),
    //         severity: Serverity.Information,
    //         url: '/store'
    //       }
    //     );
    //   });
  }
  ngOnInit(): void {
    this._$gaService.init();
    setTimeout(() => {
      this.showSplash = false;
    }, 1200);
  }

  onSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.globalSearchService.setSearchQuery(input.value);
  }

  showFeedbackDialog() {
    this.dialogService.open(FeedbackFormComponent, {
      header: "Feedback Form",
      showHeader: true,
      modal: true,
      width: "70%"
    });
  }
}