import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Notifications } from 'src/app/Database/Notificatons/Notification';
import { Notification } from 'src/app/Models/Notifications/Notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications: Notification[] = Notifications;

  constructor() { }

  send(notificaton: Notification) {
    this.notifications.push(notificaton);
    if (localStorage) {
      const notificationsString = localStorage.getItem('notifications');
      if (notificationsString) {
        const localStorageNotificatons = JSON.parse(notificationsString);
        localStorageNotificatons.push(notificaton);
        localStorage.setItem('notifications', JSON.stringify(localStorageNotificatons));
      } else {
        const notificationString: string = JSON.stringify([notificaton]);
        localStorage.setItem('notifications', notificationString);
      }
    }
  }

  delete(notificaton: Notification): Observable<Notification | null> {
    try {
      const localNotifications = this.getNotificatonsFromLocalStorage()!;
      const storedNotifications = localNotifications.filter(x => x.id !== notificaton.id && x.idN !== notificaton.idN);
      this.notifications = this.notifications.filter(x => x.id !== notificaton.id && x.idN !== notificaton.idN);
      const newNotifications = storedNotifications.filter(localNotif =>
        !this.notifications.some(notif => notif.id === localNotif.id)
      );
      this.notifications.push(...newNotifications);
      localStorage.setItem('notifications', JSON.stringify(this.notifications));
      return of(notificaton);
    } catch {
      return of(null);
    }
  }

  getAll(): Observable<Notification[]> {
    const localNotifications = this.getNotificatonsFromLocalStorage()!;
    if (localNotifications) {
      const newNotifications = localNotifications.filter(localNotif =>
        !this.notifications.some(notif => notif.id === localNotif.id)
      );

      this.notifications.push(...newNotifications);
    }
    const notifications = this.notifications.sort((a, b) => new Date(b.time).getTime() - new Date(b.time).getTime());
    return of(notifications);
  }


  getNotificatonsFromLocalStorage(): Notification[] | null {
    if (localStorage) {
      const notificationsString = localStorage.getItem('notifications');
      if (notificationsString) {
        const localNotifications: Notification[] = JSON.parse(notificationsString);
        const notifications = localNotifications.sort((a, b) => new Date(b.time).getTime() - new Date(b.time).getTime());
        return notifications;
      }
    }
    return null;
  }
  daysToDelete: number = 2;

  deleteOldLocalNotifications(daysToDelete: number): Observable<string | null> {
    if (localStorage) {
      const notificationsString = localStorage.getItem('notifications');
      const currentDate: Date = new Date();
      if (notificationsString) {
        const localNotifications: Notification[] = JSON.parse(notificationsString);
        const remainingNotifications = localNotifications.filter(x => new Date(x.time).getDate() + 2 <= currentDate.getDate());
        localStorage.setItem('notifications', JSON.stringify(remainingNotifications));
        return of("Success");
      }
    }
    return of(null);
  }


}
