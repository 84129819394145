<div class=" ">
  <div class="p-2">
    <h4 class="mb-3">{{'GlobalSearch'|translate}}
      <i class="pi pi-times right-align cursor-pointer mt-2 " (click)="closeModal()"></i>
    </h4>
    <div class="grid">
      <div class="col-10">
        <input type="text" [placeholder]="'Search'|translate" [(ngModel)]="search" pInputpInputText
          class="form-control w-full" (keyup)="onSearchChange($event)" />
      </div>
      <div class="col-2">
        <i class="pi pi-search mx-3"></i>
      </div>
    </div>
  </div>
  <div class="p-3" style="max-height: 300px; overflow-y: auto;">
    <div *ngFor="let item of filteredItems" class="bg-primary-500 border-round  m-2">
      <a *ngIf="item.url!==''" [routerLink]="item.url" class="text-white no-underline" (click)="closeModal()">
        <h5 class="p-2">
          {{ item.title| translate }} : <span class="text-sm"> {{item.shortNote | translate}}
          </span>
        </h5>
      </a>
      <a *ngIf="item.externalUrl!==''" (click)="closeModal()" [href]="item.externalUrl" target="_blank"
        class="text-white no-underline">
        <h5 class="p-2">
          {{ item.title| translate }} : <span class="text-sm"> {{item.shortNote | translate}}
          </span>

        </h5>
      </a>
    </div>
  </div>

  <div class="text-center text-500" *ngIf="filteredItems.length <= 0">
    <h5 class=""><i>{{'NoRecordFound'| translate}}...</i></h5>
  </div>
</div>