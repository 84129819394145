<form [formGroup]="form" (ngSubmit)="submitForm()" validateOnSubmit>

  <div class="field">
    <label for="message">{{'Feedback'| translate}}<span class="text-red-500">*</span> </label>
    <textarea rows="3" id="message" formControlName="message" pInputTextarea required
      [ngClass]="{'ng-invalid ng-dirty' : submitted && !form.value.message}" class="w-full" class="w-full"></textarea>
    <small class="ng-dirty ng-invalid text-red-600" *ngIf="submitted && !form.value.message">{{
      'FeedbackIsRequired' | translate }}</small>
  </div>

  <div class="field">
    <label for="name">{{ 'Name' | translate }} ({{'Optional'| translate}})
    </label>
    <input type="text" class="w-full" pInputText id="name" formControlName="name" />
  </div>
  <div class="field">
    <label for="files">{{ 'Image' | translate }} ({{'Optional'| translate}})
    </label>
    <p-fileUpload name="files" #fileUplaod chooseIcon="pi pi-cloud-upload" [chooseLabel]="'SelectImage' | translate"
      (onSelect)="onFileSelect($event)" [customUpload]="true" [auto]="true" [multiple]="true" [maxFileSize]="2048"
      [fileLimit]="1" (onRemove)="onRemoveFile($event)" accept="image/jpeg,image/gif,image/png">
      <ng-template pTemplate="content" let-removeUploadedFileCallback="removeUploadedFileCallback">

        <div *ngIf="uploadedFiles.length > 0" class="flex flex-wrap p-0 sm:p-5">
          <div class="w-full">
            <div *ngFor="let file of uploadedFiles; let i = index" class="m-0 grid 
                          flex flex-wrap align-items-center   ">
              <div class="col-9">
                <div class=" font-bold ">
                  <i [class]="'pi text-2xl  pi-image'"></i>
                  <span class="ml-3">{{ file.name }}</span>
                </div>
              </div>
              <div class="col-3">
                <button pButton icon="pi pi-times" style="float: right;" severity="secondary"
                  (click)="onRemoveFile(file)"></button>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="uploadedFiles.length <= 0" class="flex align-items-center justify-content-center flex-column">
          <i class="pi pi-cloud-upload border-2 border-circle p-3 text-xl text-400 border-400"></i>
          <p class="mt-4 mb-0">{{'DropAnImageHere'| translate}}</p>
        </div>

      </ng-template>
      <ng-template pTemplate="file"> </ng-template>
    </p-fileUpload>
  </div>
  <div class="py-4">
    <button type="submit" style="float: right;" pButton pRipple [label]="'Send' | translate" [icon]="savingBtnIcon"
      class="p-button-primary w-4 btn-right mx-4"></button>
  </div>
</form>
<p-toast [breakpoints]="{'720px': {width: '100%', right: '0', left: '0'}}"></p-toast>