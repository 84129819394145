import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Notifications } from 'src/app/Database/Notificatons/Notification';
import { Notification } from 'src/app/Models/Notifications/Notification';
import { isNew } from 'src/app/shared/utility';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications: Notification[] = Notifications;
  constructor() { }

  send(notificaton: Notification) {
    this.notifications.push(notificaton);
    // if (localStorage) {
    //   const notificationsString = localStorage.getItem('notifications');
    //   if (notificationsString) {
    //     const localStorageNotificatons = JSON.parse(notificationsString);
    //     localStorageNotificatons.push(notificaton);
    //     localStorage.setItem('notifications', JSON.stringify(localStorageNotificatons));
    //   } else {
    //     const notificationString: string = JSON.stringify([notificaton]);
    //     localStorage.setItem('notifications', notificationString);
    //   }
    // }
  }

  delete(notificaton: Notification): Observable<Notification | null> {
    try {
      const deletedNotificationsIdsString = localStorage.getItem('deleted-notifications');
      if (deletedNotificationsIdsString) {
        const deletedNotificationsIds: string[] = JSON.parse(deletedNotificationsIdsString!);
        deletedNotificationsIds.push(notificaton.id);
        const localStorageString = JSON.stringify(deletedNotificationsIds);
        localStorage.setItem('deleted-notifications', localStorageString);
      } else {
        localStorage.setItem('deleted-notifications', `[${notificaton.id}]`);
      }
      this.notifications = this.notifications.filter(x => { x.id !== notificaton.id });
      //  const localNotifications = this.getNotificatonsFromLocalStorage()!;
      //  const storedNotifications = localNotifications.filter(x => x.id !== notificaton.id && x.idN !== notificaton.idN);
      //this.notifications = this.notifications.filter(x => x.id !== notificaton.id && x.idN !== notificaton.idN);
      // const newNotifications = storedNotifications.filter(localNotif =>
      //   !this.notifications.some(notif => notif.id === localNotif.id)
      // );
      // this.notifications.push(...newNotifications);
      //   localStorage.setItem('notifications', JSON.stringify(this.notifications));
      return of(notificaton);
    } catch {
      return of(null);
    }
  }

  getAll(): Observable<Notification[]> {
    const deletedNotificationsIdsString = localStorage.getItem('deleted-notifications');
    if (deletedNotificationsIdsString) {
      const deletedNotificationsIds: number[] = JSON.parse(deletedNotificationsIdsString!);
      this.notifications = this.notifications.filter(
        notification => !deletedNotificationsIds.some(deletedId => deletedId === notification.idN)
      );
    }
    
    this.notifications.map(notification => {
      const isNewNotification = isNew(notification.time.toString(), 10);
      if (!isNewNotification) {
        this.notifications = this.notifications.filter(x => x.id !== notification.id);
      }
    })
    const notifications = this.notifications.sort((a, b) => new Date(b.time).getTime() - new Date(b.time).getTime());
    return of(notifications);
  }


  getNotificatonsFromLocalStorage(): Notification[] | null {
    if (localStorage) {
      const notificationsString = localStorage.getItem('notifications');
      if (notificationsString) {
        const localNotifications: Notification[] = JSON.parse(notificationsString);
        const notifications = localNotifications.sort((a, b) => new Date(b.time).getTime() - new Date(b.time).getTime());
        return notifications;
      }
    }
    return null;
  }
  daysToDelete: number = 2;
  deleteOldLocalNotifications(daysToDelete: number): Observable<string | null> {
    if (localStorage) {
      const notificationsString = localStorage.getItem('notifications');
      const currentDate = new Date();
      if (notificationsString) {
        const localNotifications: Notification[] = JSON.parse(notificationsString);
        const remainingNotifications = localNotifications.filter(notification => {
          const notificationDate = new Date(notification.time);
          const timeDifference = currentDate.getTime() - notificationDate.getTime();
          const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
          return daysDifference < daysToDelete;
        });
        localStorage.setItem('notifications', JSON.stringify(remainingNotifications));
        return of("Success");
      }
    }
    return of(null);
  }
}
