import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Notification, Serverity } from 'src/app/Models/Notifications/Notification';
import { WepAppInfo } from 'src/app/Models/WebApp/WebAppInfo';
import { NotificationService } from 'src/app/services/notifications/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  form: any;
  sidebarVisible: boolean = false;
  name: string = "User";
  submitted: boolean = false;
  notifications: Notification[] = [];

  appInfo: WepAppInfo = {
    name: 'dhimanp',
    version: '1.0.0',
    url: "https://www.dhimanp.com",
    description: ""
  }
  constructor(private translateService: TranslateService,
    private notificationSevice: NotificationService,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.notificationSevice.getAll().subscribe(x => {
      this.notifications = x;

      this.getAllNewNotifications();
    });
  }


  openDialog() {
    this.sidebarVisible = true;
  }
  onSubmit(form: any) {
    this.submitted = true;
    if (form.valid) {
      this.submitted = true;
    }
  }

  isNewNotification(time: any): boolean {
    const notificationTime: Date = new Date(time);
    const currentDate: Date = new Date();
    const differenceInMilliseconds = currentDate.getTime() - notificationTime.getTime();
    return differenceInMilliseconds < 24 * 60 * 60 * 1000;
  }

  deleteNotification(notification: Notification) {
    this.notificationSevice.delete(notification).subscribe(x => {

      if (x) this.newNotificationsCount--;
      this.notifications = this.notifications.filter(notification => notification.id !== x?.id && notification.idN !== x?.idN);
    });
  }

  getAndSetSeverityIcon(severity: number) {
    switch (severity) {
      case Serverity.Information:
        return " pi-info-circle text-purple-500	 ";
      case Serverity.Warn:
        return " pi-exclamation-triangle text-orange-300 "
      case Serverity.Error:
        return " pi-times-circle text-red-500 ";
      case Serverity.Success:
        return "  pi-check-circle text-green-700 	 ";
      case Serverity.Important:
        return " pi-exclamation-circle text-red-700 ";
      default:
        return "   pi-info-circle text-purple-500	 ";
    }
  }

  getAndSetSeverityClass(severity: number) {
    switch (severity) {
      case Serverity.Information:
        return "  text-purple-500	 ";
      case Serverity.Warn:
        return "  text-orange-300 "
      case Serverity.Error:
        return "  text-red-500 ";
      case Serverity.Success:
        return "   text-green-700 	 ";
      case Serverity.Important:
        return " text-red-700 ";
      default:
        return "  text-purple-500	 ";
    }
  }

  getAndSetSeverityText(severity: number) {
    switch (severity) {
      case Serverity.Information:
        return this.translateService.instant('Information');
      case Serverity.Warn:
        return this.translateService.instant('Warning');
      case Serverity.Error:
        return this.translateService.instant('Error');
      case Serverity.Success:
        return this.translateService.instant('Success');
      case Serverity.Important:
        return this.translateService.instant('Important');
      default:
        return this.translateService.instant('Information');
    }
  }

  newNotificationsCount: number = 0;
  getAllNewNotifications() {
    this.notifications.map(x => {
      if (this.isNewNotification(x.time)) {
        this.newNotificationsCount++;
      }
    })
  }

  openUrlOrDialog(notification: Notification) {
    if (notification.url) {
      this.router.navigateByUrl(notification.url);
    }
    if (notification.modal) {
      // open modal
    }
    this.sidebarVisible = false;
  }
}
