<footer class="bg-black text-center text-white mt-auto">
  <div class="container p-4 ">
    <section class="mb-4">
      <a target="_blank" *ngFor="let socialMedia of socialMedias"
        class="btn btn-outline-light btn-floating m-1 no-underline " [href]="socialMedia.url" role="button">
        <i [class]="socialMedia.icon" style="font-size: 2rem"></i>
      </a>
    </section>
    <section class="">
      <div class="row">
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">{{'Products'}}</h5>
          <ul class="list-unstyled mb-0">
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.dhimanp.EscapeRun&hl=en" target="_blank"
                class="text-white no-underline ">{{'Escape Run'}}</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.dhimanp.PinBallPlanet" target="_blank"
                class="text-white no-underline ">{{'PinBall Planet'}}</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.dhimanp.CountMyCash" target="_blank"
                class="text-white no-underline ">{{'CountMyCash'}}</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.dhimanp.GuessingTheNumber" target="_blank"
                class="text-white no-underline ">{{'Guessing The Number'}}</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">{{'WebApps'|translate}}</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="/apps/web-apps/password-generator" class="text-white no-underline ">
                {{'PasswordGenerator'|translate}}</a>
            </li>
            <li>
              <a routerLink="/apps/web-apps/guid-generator" class="text-white no-underline ">
                {{'GuidGenerator'|translate}}</a>
            </li>
            <li>
              <a routerLink="/blogs" class="text-white no-underline ">{{'Blogs'|translate}}</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">dhimanp</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="/contacts" class="text-white no-underline ">{{'ContactUs'|translate}}</a>
            </li>
            <li>
              <a routerLink="/about-us" class="text-white no-underline ">{{'AboutUs'|translate}}</a>
            </li>
            <li>
              <a routerLink="/policies/privacy-policy" class="text-white no-underline ">{{'PrivacyPolicy' |
                translate}}</a>
            </li>
            <li>
              <a routerLink="/policies/faq" class="text-white no-underline ">{{'FAQ' | translate}}</a>
            </li>
            <!-- <li>
              <a routerLink="/policies/help" class="text-white no-underline ">{{'Help' | translate}}</a>
            </li> -->
            <!-- <li>
              <a routerLink="/policies/sitemap" class="text-white no-underline ">{{'Sitemap' | translate}}</a>
            </li> -->
          </ul>
        </div>
      </div>
    </section>
  </div>
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
    © {{ currentTime.getFullYear() }} Copyright:
    <a class="text-white" href="#">dhimanp</a>
  </div>
</footer>