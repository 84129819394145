<div class=" flex align-items-center justify-content-center">
    <div class="surface-card pt-4 px-5 sm:px-7  flex flex-column w-11 sm:w-30rem" style="border-radius:14px">
        <div class="text-center">
            <span class="my-3  text-red-500" style="font-size: 5rem">☹️</span>
            <h4 class="font-bold text-3xl mt-0 mb-2 text-black">{{'Error'| translate}}
            </h4>
            <h6 class="font-bold mt-2 mb-2">{{displayData.message}}</h6>
            <p class="text-color-secondary mb-4 ">{{displayData.details}}</p>
            <button pButton pRipple label="Close" class="w-5 p-button-danger" (click)="closeModal()"></button>
        </div>
    </div>
</div>