<!-- Footer -->
<footer class="bg-black text-center text-white mt-auto">
  <!-- <div class="surface-section px-4 py-1 md:px-6 lg:px-8">
    <div class="text-700 text-center">
      <div class="text-blue-600 font-bold mb-3">&nbsp;dhimanp</div>
    </div>
  </div> -->
  <!-- Grid container -->
  <div class="container p-4 ">
    <!-- Section: Social media -->
    <section class="mb-4">
      <a target="_blank" *ngFor="let socialMedia of socialMedias"
        class="btn btn-outline-light btn-floating m-1 no-underline " [href]="socialMedia.url" role="button">
        <i [class]="socialMedia.icon" style="font-size: 2rem"></i>
      </a>
    </section>
    <!-- Section: Social media -->

    <!-- Section: Form -->
    <!-- <section class="">
        <form action=""> -->
    <!--Grid row-->
    <!-- <div class="row d-flex justify-content-center"> -->
    <!--Grid column-->
    <!-- <div class="col-auto">
              <p class="pt-2">
                <strong>Sign up for our newsletter</strong>
              </p>
            </div> -->
    <!--Grid column-->

    <!--Grid column-->
    <!-- <div class="col-md-5 col-12"> -->
    <!-- Email input -->
    <!-- <div class="form-outline form-white mb-4">
                <input type="email" id="form5Example21" class="form-control" />
                <label class="form-label" for="form5Example21">Email address</label>
              </div>
            </div> -->
    <!--Grid column-->

    <!--Grid column-->
    <!-- <div class="col-auto"> -->
    <!-- Submit button -->
    <!-- <button type="submit" class="btn btn-outline-light mb-4">
                Subscribe
              </button>
            </div> -->
    <!--Grid column-->
    <!-- </div> -->
    <!--Grid row-->
    <!-- </form> -->
    <!-- </section> -->
    <!-- Section: Form -->

    <!-- Section: Text -->

    <!-- Section: Text -->

    <!-- Section: Links -->
    <section class="">
      <!--Grid row-->
      <div class="row">
        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Products</h5>
          <ul class="list-unstyled mb-0">
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.dhimanp.EscapeRun&hl=en" target="_blank"
                class="text-white no-underline ">Escape Run</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.dhimanp.PinBallPlanet" target="_blank"
                class="text-white no-underline ">PinBall Planet</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.dhimanp.CountMyCash" target="_blank"
                class="text-white no-underline ">Count My Cash</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=com.dhimanp.GuessingTheNumber" target="_blank"
                class="text-white no-underline ">Guessing The Number</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Web Apps</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="/apps/web-apps/password-generator" class="text-white no-underline ">Password Generator</a>
            </li>
            <li>

              <a routerLink="/apps/web-apps/guid-generator" class="text-white no-underline ">Guid
                Generator</a>
            </li>
            <li>

              <a routerLink="/blogs" class="text-white no-underline ">Blogs</a>
            </li>

            <!-- <li>
                <a href="http://kitepcgames.000webhostapp.com/kandela-village/" target="_blank"  class="text-white">Kandela Website</a>
              </li>
              <li>
                <a href="https://kitepcgames.000webhostapp.com/PaperRockScissor/index.html" target="_blank"  class="text-white">Paper Rock Scissor</a>
              </li> -->
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <!-- <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 class="text-uppercase">Projects</h5>
  
            <ul class="list-unstyled mb-0">
              <li>
                <a href="http://kitepcgames.000webhostapp.com/e-office/" target="_blank" class="text-white">E-Office Clone</a>
              </li>
              <li>
                <a href="#!" class="text-white">Link 2</a>
              </li>
              <li>
                <a href="#!" class="text-white">Link 3</a>
              </li>
           
            </ul>
          </div> -->
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">dhimanp</h5>

          <ul class="list-unstyled mb-0">
            <!-- <li>
                <a routerLink="/profile" class="text-white">Me</a>
              </li> -->
            <li>
              <a routerLink="/contacts" class="text-white no-underline ">Contact</a>
            </li>
            <li>
              <a routerLink="/about-us" class="text-white no-underline ">About Us</a>
            </li>
            <li>
              <a routerLink="/policies/privacy-policy" class="text-white no-underline ">{{'PrivacyPolicy' |
                translate}}</a>
            </li>
            <li>
              <a routerLink="/policies/faq" class="text-white no-underline ">{{'FAQ' | translate}}</a>
            </li>
            <!-- <li>
              <a routerLink="/policies/help" class="text-white no-underline ">{{'Help' | translate}}</a>
            </li> -->
            <!-- <li>
              <a routerLink="/policies/sitemap" class="text-white no-underline ">{{'Sitemap' | translate}}</a>
            </li> -->
            <!-- <li>
                <a href="#!" class="text-white">Link 3</a>
              </li>
              <li>
                <a href="#!" class="text-white">Link 4</a>
              </li> -->
          </ul>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </section>
    <!-- Section: Links -->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
    © {{ currentTime.getFullYear() }} Copyright:
    <a class="text-white" href="#">dhimanp</a>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->