import { NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ChipModule } from 'primeng/chip';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { AvatarModule } from 'primeng/avatar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SliderModule } from 'primeng/slider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { PasswordModule } from 'primeng/password';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ApiService } from 'src/app/services/generic/api.service';
import { FileUploadModule } from 'primeng/fileupload';
// import { EditorModule } from 'primeng/editor';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeModule } from 'primeng/badge';
import { KnobModule } from 'primeng/knob';
import { ErrorPageComponent } from '../error-page/error-page.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MegaMenuModule } from 'primeng/megamenu';
import { MessagesModule } from 'primeng/messages';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { JsonDataService } from 'src/app/services/generic/json-data.service';


@NgModule({
  declarations: [ErrorPageComponent, ConfirmDialogComponent],
  imports: [
    TooltipModule,
    DialogModule,
    SidebarModule,
    RippleModule,
    AvatarModule,
    CheckboxModule,
    ChipsModule,
    FormsModule,
    TagModule,
    ToastModule,
    RadioButtonModule,
    SliderModule,
    InputSwitchModule,
    CardModule,
    ChipModule,
    TableModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputSwitchModule,
    DialogModule,
    ToastModule,
    DynamicDialogModule,
    InputNumberModule,
    InputTextModule,
    InputTextModule,
    SidebarModule,
    AccordionModule,
    PasswordModule,
    SkeletonModule,
    ChipModule,
    AvatarModule,
    ProgressBarModule,
    InputTextareaModule,
    DividerModule,
    TabViewModule,
    SplitButtonModule,
    FileUploadModule,
    TranslateModule,
    BadgeModule,
    KnobModule,
    CarouselModule,
    MegaMenuModule,
    MessagesModule,
    OverlayPanelModule
  ],
  exports: [
    DialogModule,
    SidebarModule,
    RippleModule,
    AvatarModule,
    CheckboxModule,
    ChipsModule,
    FormsModule,
    TagModule,
    ToastModule,
    RadioButtonModule,
    SliderModule,
    InputSwitchModule,
    CardModule,
    ChipModule,
    CarouselModule,
    GalleriaModule,
    TableModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    InputSwitchModule,
    DialogModule,
    ToastModule,
    DynamicDialogModule,
    InputNumberModule,
    InputTextModule,
    InputTextModule,
    SidebarModule,
    AccordionModule,
    PasswordModule,
    SkeletonModule,
    ChipModule,
    AvatarModule,
    ProgressBarModule,
    InputTextareaModule,
    DividerModule,
    TabViewModule,
    ButtonModule,
    SplitButtonModule,
    FileUploadModule,
    TranslateModule,
    BadgeModule,
    ChipModule,
    KnobModule,
    TooltipModule,
    MegaMenuModule,
    MessagesModule,
    OverlayPanelModule,

    ErrorPageComponent,
    ConfirmDialogComponent
  ],
  providers: [ApiService, JsonDataService],

})
export class SharedModule { }