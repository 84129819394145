import { DynamicDialogRef } from "primeng/dynamicdialog";

export const dialogData = (header: string, message: string, details: string) => {
    return {
        header: header,
        showHeader: false,
        contentStyle: { overflow: 'auto', borderRadius: "5px" },
        styleClass: "rounded ",
        baseZIndex: 10000,
        modal: true,
        data: {
            message: message,
            details: details
        },
    }
}

export  const showError=(header: string, message: string, details: string, errorRefDialog:DynamicDialogRef, dialogService:any, ErrorPageComponent:any)=>{
    var isErrorDialogDisplayed:boolean = true;
    if (isErrorDialogDisplayed && !(details === '403' || details === '401')) {
      errorRefDialog = dialogService.open(ErrorPageComponent,
        dialogData(header, message, details));
      errorRefDialog.onClose.subscribe(result => {
        isErrorDialogDisplayed = false;
      });
    }
}
