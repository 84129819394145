<div class=" flex align-items-center justify-content-center">
    <div class="surface-card pt-4 px-5 sm:px-7  flex flex-column w-11 sm:w-30rem" style="border-radius:14px">
        <div class="text-center">
            <i class="my-3 pi pi-exclamation-circle text-orange-500" style="font-size: 5rem"></i>
            <h4 class="font-bold text-3xl mt-0 mb-2 text-black"> {{'AreYouSure'| translate}}
            </h4>
            <p class=" mb-6 mt-2">{{displayData.message}}</p>
            <button pButton pRipple icon="pi pi-times" class=" mr-3 surface-500	" [label]="'Cancel'|translate"
                (click)=" closeModal(false)"></button>
            <button pButton pRipple icon="pi pi-check" class="p-button-danger ml-3" [label]="'Yes'|translate"
                (click)=" closeModal(true)"></button>

        </div>
    </div>
</div>