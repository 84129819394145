<button class="btn text-white" (click)="openDialog()">
    <i class="pi pi-user"></i>
</button>


<p-sidebar [(visible)]="sidebarVisible" position="right" [blockScroll]="true" styleClass=" sm:w-8 md:w-3 mx-0">
    <ng-template pTemplate="header">
        <span class="font-semibold text-xl">{{'UserProfile' | translate}}</span>
    </ng-template>
    <form ngForm="ngForm" (ngSubmit)="onSubmit(name) " class="m-0">
        <div class="grid  centered-content m-0 w-full ">
            <div class="col-2">
                <p-avatar icon="pi pi-user" class="" shape="circle" />
            </div>
            <div class="col-6">
                <!-- <label for="name">{{"YourName"}}</label> <span class="text-danger-500">*</span> -->
                <input [placeholder]="'User'| translate" type="text" id="name" name="name" [(ngModel)]="name" ngModel
                    pInputText />
            </div>
            <div class="col-4">
                <button pButton pRipple class="p-button-primary">
                    {{"Save"| translate}}
                </button>
            </div>
        </div>
        <div class="" *ngIf="(!name || name==='')&& submitted">
            <p class="text-red-500">{{'UserNameIsRequired'}}</p>
        </div>

    </form>
    <div class="form-group mt-2">
        <div class="grid">
            <div class="col-3">
                <label>{{'Language'| translate}}</label>
            </div>
            <div class="col-9">
                <select [(ngModel)]="selectedLanguage" pDropdown class="form-control">
                    <option *ngFor="let option of languages" [value]="option">{{option}}</option>
                </select>
            </div>

        </div>
    </div>

    <ng-template pTemplate="footer">
        <hr />
        <div class="grid">
            <div class="col-8 ">
                <h6>{{'ShareYourProfileAs'| translate}} </h6>
                <h6>{{userInfo.name}}

                    <span class="ml-3"> <i class="fa fa-share-square cursor-pointer"></i>
                    </span>
                </h6>
            </div>
            <div class="col-4">
                <qrcode [qrdata]="userInfo.profileUrl" [width]="100" [errorCorrectionLevel]="'M'">
                </qrcode>
            </div>
        </div>
    </ng-template>
</p-sidebar>
<!-- <p-button (onClick)="sidebarVisible = true" icon="pi pi-arrow-right" /> -->