<span class="ml-3 cursor-pointer text-white" (click)="openDialog()">
    <i class="pi pi-user"></i>
</span>

<p-sidebar [(visible)]="sidebarVisible" position="right" [blockScroll]="true" styleClass=" sm:w-8 md:w-3 mx-0">
    <ng-template pTemplate="header">
        <span class="font-semibold text-xl">{{'UserProfile' | translate}}</span>
    </ng-template>
    <form ngForm="ngForm" (ngSubmit)="onSubmit(name) " class="m-0">
        <label class="text-center pl-8">{{'Name'|translate}}</label>
        <div class="grid  centered-content m-0 w-full ">
            <div class="col-2">
                <p-avatar icon="pi pi-user" class="" shape="circle" />
            </div>
            <div class="col-6">
                <!-- <label for="name">{{"YourName"}}</label> <span class="text-danger-500">*</span> -->
                <input [placeholder]="'User'| translate" type="text" id="name" name="name"
                 [(ngModel)]="name" ngModel class="w-full"
                    pInputText />
            </div>
            <div class="col-4">
                <button pButton pRipple class="p-button-primary">
                    {{"Save"| translate}}
                </button>
            </div>
        </div>
        <div class="" *ngIf="(!name || name==='')&& submitted">
            <p class="text-red-500">{{'UserNameIsRequired'|translate}}</p>
        </div>

    </form>
    <div class="form-group mt-2">
        <div class="grid">
            <div class="col-3">
                <label>{{'Language'| translate}}</label>
            </div>
            <div class="col-9">
                <select [(ngModel)]="selectedLanguage" pDropdown class="form-control"
                    (change)="onLanguageChange($event)">
                    <option *ngFor="let option of languages" [value]="option.value">{{option.label|translate}}</option>
                </select>
            </div>
        </div>
    </div>

    <!-- <ng-template pTemplate="footer">
        <hr />
        <div class="grid">
            <div class="col-8 ">
                <h6>{{'ShareYourProfileAs'| translate}} </h6>
                <h6>{{userInfo?.userConfig?.name}}

                    <span class="ml-3"> <i class="fa fa-share-square cursor-pointer"></i>
                    </span>
                </h6>
            </div>
            <div class="col-4">
                <qrcode [qrdata]="userInfo?.userConfig?.profileUrl!" [width]="100" [errorCorrectionLevel]="'M'">
                </qrcode>
            </div>
        </div>
    </ng-template> -->
    <ng-template pTemplate="footer">
        <hr />
        <div class="grid m-0 p-0">
            <div class="col-8">
                <h6>{{'ShareWebsite'| translate}} </h6>
                <a href="https://dhimanp.com/" target="_blank">
                    <span> {{appInfo.name}}.com</span>
                </a>
                <span class="ml-3"> <i class="fa fa-share-square cursor-pointer"></i>
                </span>
                <p>{{'Version'| translate}} {{appInfo.version}}</p>
                <a routerLink="/policies/privacy-policy" class="no-underline "
                    (click)="sidebarVisible=false">{{'PrivacyPolicy' |
                    translate}}</a>
                <!-- <p-divider layout="vertical" /> -->
            </div>
            <div class="col-4">
                <qrcode [qrdata]="appInfo.url" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </div>
    </ng-template>
</p-sidebar>
<!-- <p-button (onClick)="sidebarVisible = true" icon="pi pi-arrow-right" /> -->
<p-toast [breakpoints]="{'620px': {width: '100%', right: '0', left: '0'}}"></p-toast>