<div class="main-bg m-0 pt-1 pb-2">
  <div class="pt-1 ">
    <i class="pi pi-align-justify mx-3 text-white cursor-pointer" [pTooltip]="'Menu'| translate" tooltipPosition="right"
      (click)="sidebarVisible = true" ></i>
    <a routerLink="/" class="no-underline mr-2">
      <span class="bg-white p-1" style="border-radius: 35px 36px 37px 0px"><span class="text-danger logo">
          dhiman</span><span class="text-warning logo">p </span></span>
    </a>
    <a routerLink="/store" class="no-underline " [pTooltip]="'dhimanpStore'| translate">
      <i class="pi pi-th-large text-white "></i>
    </a>
    <span class="right-align pr-4 py-0 my-0">
      <i class="pi pi-search text-white" (click)="openGlobalSearch()">
      </i>
      <app-notification class="mr-2"></app-notification>
      <app-user-profile></app-user-profile>
      <!-- <i class="fa fa-arrows-alt text-white cursor-pointer  " aria-hidden="true" *ngIf="isFullScreenBtn"
        (click)="openFullscreen()" [pTooltip]="'FullScreen'| translate" tooltipPosition="left"></i>
      <i *ngIf="!isFullScreenBtn" (click)="closeFullscreen()" class="fa fa-compress text-white  cursor-pointer "
        aria-hidden="true" [pTooltip]="'NormalScreen'| translate" tooltipPosition="left"></i> -->
    </span>
  </div>
</div>

<div class=" m-0 flex justify-content-center ">
  <p-sidebar #sidebarRef [(visible)]="sidebarVisible" [blockScroll]="true">
    <ng-template pTemplate="header">
      <span class="font-semibold text-xl">
        <span class="font-semibold text-2xl text-primary">
          <span class="main-bg p-2" style="border-radius: 35px 36px 37px 0px"><span
              class="text-white">dhiman</span><span class="text-white">p</span></span>
        </span>
      </span>
    </ng-template>
    <ng-template pTemplate="headless">
      <div class="flex flex-column h-full">
        <div class="overflow-y-auto">
          <ul class="list-none px-3 m-0 ">
            <li>
              <a pRipple
                class="flex align-items-center no-underline 
                 cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                routerLink="/" (click)="sidebarVisible = false">
                <i class="pi pi-home mr-2" aria-hidden="true"></i>

                <span class="font-medium">{{'Home'|translate}}</span>
              </a>
            </li>
            <li>
              <a pRipple
                class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                routerLink="/blogs" (click)="sidebarVisible = false">
                <i class="fa fa-book mr-2" aria-hidden="true"></i>

                <span class="font-medium">{{'Blogs'|translate}}</span>
              </a>
            </li>
            <li>
              <a pRipple
                class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                routerLink="/learning" (click)="sidebarVisible = false">
                <i class="fa fa-book mr-2" aria-hidden="true"></i>

                <span class="font-medium">{{'Learning'}}</span>
              </a>
            </li>
          </ul>
          <ul class="list-none px-3 m-0">
            <li>
              <a pRipple
                class="flex align-items-center no-underline cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                routerLink="store" (click)="sidebarVisible = false">
                <i class="pi pi-th-large mr-2" aria-hidden="true"></i>
                <span class="font-medium">{{'Store'|translate}}</span>
              </a>
            </li>
          </ul>
          <ul class="list-none px-3 m-0">
            <li>
              <div pRipple pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                leaveActiveClass="slideup"
                class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple">
                <span class="font-medium">{{'Contacts'|translate}}</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden">
                <li>
                  <a pRipple
                    class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                    routerLink="/about-us" (click)="sidebarVisible = false">
                    <i class="fa fa-info-circle mr-2" aria-hidden="true"></i>

                    <span class="font-medium">{{'About'|translate}}</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                    class="flex align-items-center no-underline  cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple"
                    routerLink="/contacts" (click)="sidebarVisible = false">
                    <i class="fa fa-user-circle mr-2" aria-hidden="true"></i>

                    <span class="font-medium">{{'ContactUs'|translate}}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
  </p-sidebar>
</div>