<footer class="bg-black text-center text-white mt-auto">
  <div class="container p-4 ">
    <!-- <section class="mb-4">
      <a target="_blank" *ngFor="let socialMedia of socialMedias"
        class="btn btn-outline-light btn-floating m-1 no-underline " [href]="socialMedia.url" role="button">
        <i [class]="socialMedia.icon" style="font-size: 2rem"></i>
      </a>
    </section> -->
    <section class="">
      <div class="row">
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0" *ngIf="footerData">
          <h5 class="text-uppercase">{{footerData[0].column}}</h5>
          <ul class="list-unstyled mb-0">
            <li *ngFor="let item of footerData[0].items">
              <a [href]="item.url" target="_blank" class="text-white no-underline ">{{item.title}}</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0" *ngIf="footerData">
          <h5 class="text-uppercase">{{footerData[1].column}}</h5>
          <ul class="list-unstyled mb-0">
            <li *ngFor="let item of footerData[1].items">
              <a [routerLink]="item.routerLink" class="text-white no-underline ">{{item.title}}</a>
            </li>
          </ul>
        </div>
       
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">dhimanp</h5>

          <ul class="list-unstyled mb-0">
            <li>
              <a routerLink="/contacts" class="text-white no-underline ">{{'ContactUs'|translate}}</a>
            </li>
            <li>
              <a routerLink="/about-us" class="text-white no-underline ">{{'AboutUs'|translate}}</a>
            </li>
            <li>
              <a routerLink="/policies/privacy-policy" class="text-white no-underline ">{{'PrivacyPolicy' |
                translate}}</a>
            </li>
            <li>
              <a routerLink="/policies/faq" class="text-white no-underline ">{{'FAQ' | translate}}</a>
            </li>
            <!-- <li>
              <a routerLink="/policies/help" class="text-white no-underline ">{{'Help' | translate}}</a>
            </li> -->
            <!-- <li>
              <a routerLink="/policies/sitemap" class="text-white no-underline ">{{'Sitemap' | translate}}</a>
            </li> -->
          </ul>
        </div>
      </div>
    </section>
  </div>
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
    © {{ currentTime.getFullYear() }} Copyright:
    <a class="text-white" href="#">dhimanp</a>
  </div>
</footer>