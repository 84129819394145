export const environment = {
  production: false,
  app: {
    baseUrl: window.location.href
  },
  api: {
    baseUrl: "https://dhimanp-node-apis.onrender.com/api"
  },
  googleAnalytics: ''

};
