import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomeModule),
    // canActivate: [AuthGuard],
  },
  // {
  //   path: 'apps/android-apps',
  //   loadChildren: () =>
  //     import('./apps/android-apps/android-apps.module').then((m) => m.AndroidAppsModule),

  // },
  {
    path: 'apps/web-apps',
    loadChildren: () =>
      import('./apps/web-apps/web-apps.module').then((m) => m.WebAppsModule),

  },
  {
    path: 'blogs',
    loadChildren: () =>
      import('./blogs/blogs.module').then((m) => m.BlogsModule),

  },
  {
    path: 'learning',
    loadChildren: () =>
      import('./learning/learning.module').then((m) => m.LearningModule),

  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./abouts/abouts.module').then((m) => m.AboutsModule),

  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./contacts/contacts.module').then((m) => m.contactsModule),

  },
  {
    path: 'store',
    loadChildren: () =>
      import('./dhimanp-store/dhimanp-store.module').then((m) => m.DhimanpStoreModule),
  },

  {
    path: 'policies',
    loadChildren: () =>
      import('./public-pages/public-pages.module').then((m) => m.PublicPagesModule),
    // canActivate: [AuthGuard],
  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/page-not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
