import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HeaderMenuComponent } from './layout/topbar/header.component';
import { SideBarMenuComponent } from './layout/sidebar-menu/sidebar-menu.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SharedModule } from './shared/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StyleClassModule } from 'primeng/styleclass';
import { UserProfileComponent } from './profile/user-profile/user-profile.component';
import { NotificationComponent } from './notifications/notification/notification.component';
import { QRCodeModule } from 'angularx-qrcode';
import { GlobalSearchComponent } from './shared/global-search/global-search.component';
import { FeedbackFormComponent } from './contacts/feedback-form/feedback-form.component';
import { GoogleAnalyticsService } from './services/google/google-analytics.service';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

// Required for AoT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderMenuComponent,
    SideBarMenuComponent,
    FooterComponent,
    UserProfileComponent,
    NotificationComponent,
    GlobalSearchComponent,
    FeedbackFormComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StyleClassModule,
    QRCodeModule,
    TranslateModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
