export interface Notification {
    id: string;
    idN: number;
    subject: string;
    content: string;
    senderIcon?: string;
    time: Date;
    url?: string;
    severity: number;
    modal?: any;
}

export enum Serverity{
    Information,
    Success,
    Warn,
    Error,
    Important
}