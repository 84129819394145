import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JsonDataService } from './generic/json-data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  readonly apiBaseUrl: string = environment.api.baseUrl;
  
    constructor(private apiService: JsonDataService) { }
    get(): Observable<any> {
      return this.apiService.get(`footer/footer.json`);
    }
}
