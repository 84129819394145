import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserProfile } from 'src/app/Models/Users/User';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  constructor() { }
  
  addOrUpdate(user: UserProfile): Observable<UserProfile> {
    if (localStorage) {
      localStorage.setItem('user-config', JSON.stringify(user.userConfig));
      localStorage.setItem('apps-config', JSON.stringify(user.userApps));
    } else {
      console.log("user is not created...");
    }
    return of(user);
  }
  get(): Observable<UserProfile | null> {
    if (localStorage) {
      const userConfig = localStorage.getItem('user-config');
      const appsConfig = localStorage.getItem('apps-config');
      const userProfile: UserProfile = {
        userConfig: JSON.parse(userConfig!),
        userApps: JSON.parse(appsConfig!)
      }
      return of(userProfile);
    } else {
      console.log("user not found...");
    }
    return of(null);
  }
}
