import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  accounts = [
    {
      title: 'Google Play Console',
      icon: '',
      url: 'https://play.google.com/store/apps/dev?id=4916159402707872220&hl=en',
    },
    { title: 'Linkedin', icon: '', url: 'https://www.linkedin.com/in/paras-dhiman-3318751b9/' },
    { title: 'Github', icon: '', url: 'https://github.com/Dhiman-Paras' },
    { title: 'Gmail', icon: '', url: 'mailto:parasdhiman07@gmail' },
    { title: 'YouTube', icon: '', url: 'https://www.youtube.com/channel/UCcL7uhEf-LYDkTLrP8NwgHQ' },
    { title: 'Instagram', icon: '', url: 'https://www.instagram.com/parasdhiman36/' },
    { title: 'Facebook', icon: '', url: 'https://www.facebook.com/profile.php?id=100080020596548' },
  ];
  currentTime = new Date();
  socialMedias = [] as any;//this.accounts as any;
  socialMediaClass = 'btn btn-outline-light btn-floating m-1'
}
