import { Component } from '@angular/core';
import { GlobalSearchService } from 'src/app/services/global-search.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GlobalSearchList } from 'src/app/Database/GlobalSearch/GlobalSearch';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent {
  sidebarVisible: boolean = false;

  items = GlobalSearchList;
  filteredItems: any[] = GlobalSearchList;
  search: string = "";

  constructor(
    // private searchService: GlobalSearchService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {


  }

  ngOnInit() {
    this.displayData = this.config.data;

    // this.searchService.searchQuery$.subscribe(query => {
    //   this.filteredItems = this.items.filter(item =>

    //     item.title.toLowerCase().includes(query.toLowerCase()
    //   )
    //   );
    // });
  }

  openDialog() {
    this.sidebarVisible = true;
  }

  onSearchChange(event: any) {
    if (this.search.trim() === "" || !this.search) {
      this.filteredItems = this.items;
    } else {
      this.filteredItems = this.items.filter(x => x.title.trim().toLowerCase().includes(this.search.trim().toLowerCase()))
    }
  }

  displayData = { message: "", details: "" }

  closeModal(decision: boolean = false) {
    this.ref.close(decision);
  }
}
