import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WepAppInfo } from 'src/app/Models/WebApp/WebAppInfo';
import { Language, Stream, Theme, UserConfig } from 'src/app/Models/Users/User';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {
  form: any;
  sidebarVisible: boolean = false;
  name: string = "User";
  submitted: boolean = false;

  appInfo: WepAppInfo = {
    name: 'dhimanp',
    version: '1.0.0',
    url: "https://www.dhimanp.com",
    description: 'A product-based company that offers innovative software solutions and insightful blogs to empower developers and tech enthusiasts.'
  }

  userInfo: UserConfig = {
    name: this.name,
    profileUrl: `dhimanp.com/user-profile/${this.name}/extra-info`,
    language: Language.English,
    stream: Stream.General,
    theme: Theme.Theme1
  }

  selectedLanguage: Language | Language.English = this.userInfo.language;
  languages: string[] = ["English", "Hindi"];
  constructor(private translateService: TranslateService) { }

  openDialog() {
    this.sidebarVisible = true;
  }
  onSubmit(form: any) {
    this.submitted = true;
    if (form.valid) {
      this.submitted = true;
    }
  }
}
